import React from 'react'
import { useRef } from 'react'
import { Parallax, ParallaxLayer, IParallax } from '@react-spring/parallax'
import './index.css'

export default function App() {
  const parallax = useRef<IParallax>(null!)

  const env = process.env;
  env.PUBLIC_URL = env.PUBLIC_URL || '';
  const backgroudGrassUrl = `${env.PUBLIC_URL}/img/background_grass_1080.gif`
  const backgroudWavesUrl = `${env.PUBLIC_URL}/img/background_wave_1080_alpha.gif` 

  return (
    <div style={{ width: '100%', height: '100%', background: '#FFFFFF' }}>
      <Parallax ref={parallax} pages={3.8}>


        <ParallaxLayer
          offset={0}
          speed={0.3}
          factor={0.5}
          style={{
          }}>
        </ParallaxLayer>
        <ParallaxLayer
          offset={0.5}
          speed={0.1}
          factor={1.2}
          // onClick={() => parallax.current.scrollTo(1.2)}
          style={{
            backgroundImage: `url(${backgroudWavesUrl})`,
          }}>
        </ParallaxLayer>

        <ParallaxLayer
          sticky={{ start: 0., end: 0.6 }}
          speed={0.0}
          style={{
            color: 'black',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: -1,
          }}
        >
          <div>
            <h1
            style={{
              fontSize: '102px',
              letterSpacing: '-2px',
              display: 'block',
              marginBottom: '20px',
            }}
            >Marcella Valentine</h1>
            <h2 style={{
              fontSize: '28px',              
              fontWeight: 'normal',
              textAlign: 'center',
              display: 'block',
              marginTop: '0px',
            }}>Cat Herder & Baker</h2>
          </div>
        </ParallaxLayer>

        <ParallaxLayer
          offset={1.7}
          factor={1}
          speed={-0}
          // onClick={() => parallax.current.scrollTo(2.4)}
          style={{
            // background: "rgb(12,0,255)",
            // background: "linear-gradient(180deg, rgba(12,0,255,1) 0%, rgba(255,255,255,1) 49%, rgba(31,255,0,1) 100%)"

          }}
        >
          <div className='faqs-div'>
            <h1>Hi, I’m Marcella (she/her)</h1>          
              {/* <p>I’m passionate about ML and team workflows.</p>
              <p>I run a bookclub that has met every 3 weeks since 2016.</p>
              <p>I grew up in the San Francisco East Bay.</p>
              <p>I love to swim and talk too much about geomorphology on hikes. </p>
              <p>I’m a keen baker and crafter of many things.</p> */}
              <ul>
                <li>I’m passionate about ML and team workflows.</li>
                <li>I run a bookclub that has met every 3 weeks since 2016.</li>
                <li>I grew up in the San Francisco East Bay.</li>
                <li>I love to swim and talk too much about geomorphology on hikes. </li>
                <li>I’m a keen baker and crafter of many things.</li>
              </ul>
          </div>
        </ParallaxLayer>


        <ParallaxLayer
          offset={2.7}
          factor={1.1}
          speed={-0}
          style={{
            display: 'flex',
            backgroundImage: `url(${backgroudGrassUrl})`,
            backgroundPosition: 'right',

          }}
          // onClick={() => parallax.current.scrollTo(0)}
          >
          <div className='bio-div'>
          <p>
            Throughout my career I’ve done my best to maximize good through empathy and focusing my intentions on learning. Learning about others and their experiences. 
            Learning when I’ve made a mistake, what led there, and how I can avoid repeating it. 
            Learning where I or others have excelled and how that success was achieved. 
            I’ve always focused on how I can lift up people around me and how I can help reduce barriers. 
            <br/>
            <br/>
            I love connecting with individuals and teams to help them design more efficient and enjoyable workflows. 
          </p>
          </div>
        </ParallaxLayer>
      </Parallax>
    </div>
  )
}
